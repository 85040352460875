import { create } from '@storybook/theming/create';

export default create({
  base: 'light',
  brandTitle: 'BENTELE-IT Design System',
  brandUrl: 'https://bentele-it.de',
  brandImage: '/logo.png',
  brandTarget: '_self',
  colorPrimary: '#000',
});
